import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Leo.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Gemini Health
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/gemini"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Gemini</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Gemini Health</h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Gemini Health</h1>
                <h2 className="text-sm md:text-base ml-4">May 21 - Jun 21</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Gemini Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Gemini Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Gemini Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-love");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-nature");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-man");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-traits");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-facts");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
              <p className="playfair text-black text-xl md:text-3xl">Gemini Health</p><br/>
              Gemini Signs regulate language, smell, and lungs. All this depends on the airflow you breathe. Their health is affected primarily by emotional weakness, limitation, and overactivity. Your busy brain needs a rest to stay healthy. When Mercury is positioned weakly in your natal chart, you are more likely to suffer from stress-related issues such as anxiety, irritability, and mood swings. Rest is needed to heal irritated nerves and rejuvenate overactive brain cells. <br/>
              <br></br>
              To avoid disease, it should be kept warm in winter. You are active and tend to do many things at once, which is detrimental to your health. Sometimes you get overexcited, sometimes you get nervous, and that's stress. They are not eating or sleeping enough. This is very important for staying healthy. Your health is very delicate and requires regular rest and exercise. <br/>
              <br></br>
              <p className="playfair text-black text-xl md:text-3xl"> Potential Health Concerns</p><br/>
              Lack of fresh, unpolluted air, bright sunlight, and reduced activity increases the likelihood of hospital visits. Accidents and breakdowns can occur due to the unnecessary stress that runs from one project to another to meet deadlines. <br/>
              <br></br>
              Because of this, you may suffer from infections in your shoulders, arms, hands, and fingers. Your lungs and intestines may be weakened. They may suffer from respiratory diseases such as asthma. You probably have problems with your feet, back, arthritis, rheumatism, and even overthinking can give you migraines and insomnia. <br/>
              <br></br>
              Restlessness can lead to mental and nervous exhaustion. They are prone to frequent colds, flu, coughs, and viral infections. <br/>
              <br></br>
              <p className="font-bold text-orange-500 inline-block mr-2">Also read:</p>Gemini Yearly Horoscope for Health, Well-being and Happiness<br/>
              <br></br>
              <p className="playfair text-black text-xl md:text-3xl">Food for Gemini</p><br/>
              They say they have little appetite and prefer chocolates and appetizer platters. Adequate amounts of potassium chloride must be consumed in the diet to build fiber, and tissue and maintain the health of various organs in the body. Clears blockages in the lungs and bronchi. You like to snack between meals, but I recommend eating low-sugar foods such as fruits and yogurt. Beans, tomatoes, carrots, oranges, plums, apricots, pink grapefruit, rice, cabbage, and broccoli are high in potassium chloride and should be eaten. <br/>
              <br></br>
              Eat foods rich in iron and calcium, such as spinach, beets, milk, and bananas. We recommend avoiding or minimizing coffee, carbonated drinks, yeasty foods, and refined sugar in your diet. Coffee and stimulants are bad for your health. Instead, you should drink herbal teas, green teas, and other beverages to soften the effects on your nerves. Junk food should be avoided to maintain energy and a good mood. You need to eat brain-healthy nutrients like almonds, walnuts, and avocados.<br/>
              <br></br>
              <p className="font-bold text-orange-500 inline-block mr-2">Also Read:</p>Gemini Daily Horoscope for Health and Well-being<br/>
              They are slim and nimble in build and above average in size. They have beautiful bright eyes and expressions. Your eyelashes are long and thick. Their eyes don't stay in one place, they move quickly. They have expressive mouths and white skin. Your shoulders are usually narrow and your body is fit. It has fast legs and long limbs. You have a habit of swinging your arm in one long sweeping motion of his. <br/>
              <br></br>
              Her features are small, unique, soft, comforting, and finely chiseled. You are upright and have a straight, thin nose. The chin is narrow and well-placed. The lips are thin, but the chin is pointed, sharp, and wide. Their heads are often long and narrow between their ears, with high cheekbones and attractively wide mouths. Her hair is fine-textured, of average length, and dark in color throughout. You have an impressive, high-pitched voice. You will always look youthful regardless of your age. <br/>
              <br></br>
              <p className="playfair text-black text-xl md:text-3xl">Gemini beauty</p><br/>
              You were born beautiful with inner and outer beauty and always look younger than your actual age. They look best in orange and yellow colors. They have bright, sparkling, magically open eyes and can surround them with the perfect combination of glitter and sparkle. Gemini’s are versatile and tend to have diversity. So have a huge wardrobe and keep it updated with new fashion styles and trends. <br/>
              <br></br>
              Your dress will look elegant on you and will be a fashion statement at a social gathering. You look best with light and airy hairstyles and your haircut should be light, free, and flowing. Apply mascara You can make your sparkling eyes more attractive. They love social events and often reach out. Beautifully shimmering eyeshadow creates a sparkling and powerful eye. Vibrant lipsticks and sheer shimmers enhance your natural beauty and add a touch of luxury to your personality.<br/>
              <br></br>
              To know more or you have any queries, talk to the best astrologer now to get rid from all your problems.<br/>
              <br></br>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
